@import '../../scss/theme';

.page-signin {
  // background-color: $auth_background;
  background: linear-gradient(45deg, #434d5a 0%, #192332 100%);
  position: relative;
}

.Auth {
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    -webkit-text-fill-color: #fff !important;
    -webkit-box-shadow: 0 0 0px 1000px transparent inset !important;
    transition: background-color 5000s ease-in-out 0s;
    caret-color: white;
  }

  .btn-darkprimary {
    background-color: #66c1bf !important;
    border-color: #66c1bf !important;
  }

  .header {
    padding: 21px 0 0 0;
    display: flex;
    flex-direction: row;
  }

  .orbs1 {
    position: absolute;
    top: 189px;
    left: 110px;

    img {
      width: 72px;
      height: 72px;
      border-radius: 50%;
    }
  }

  .orbs2 {
    position: absolute;
    top: 468px;
    right: 165px;

    img {
      width: 28px;
      height: 28px;
      border-radius: 50%;
    }
  }

  h1 {
    margin-bottom: 15px;
    color: #ffffff;
    font-family: 'Messina Sans';
    font-size: 36px;
    font-weight: bold;
    line-height: 47px;
    text-align: center;
  }

  p {
    margin-bottom: 35px;
    color: rgba(255, 255, 255, 0.7);
    font-family: 'Messina Sans';
    font-size: 12px;
    line-height: 24px;
    text-align: center;
  }

  svg {
    display: block;
    margin: auto;
  }

  .btn-link {
    color: #ffffff;
    font-family: 'Messina Sans';
    font-size: 12px;
    line-height: 24px;
    font-weight: normal;
  }

  .nobreak {
    word-break: keep-all;
    white-space: nowrap;
  }

  .btn-register {
    color: #ffffff;
    font-family: 'Messina Sans';
    font-size: 14px;
    line-height: 19px;
    text-align: center;
    font-weight: normal;
    padding: 8px 20px;
    cursor: pointer;
  }

  .form-signin {
    padding: 30px 15px;
    width: 380px;
    max-width: 380px;
    height: 380px;
    // background-color   : #fff;
    // border-radius      : 10px;
    position: absolute;
    left: 50%;
    top: 150px;
    transform: translate(-50%, 0);

    label {
      color: rgba(255, 255, 255, 0.75);
      font-family: 'Messina Sans';
      font-size: 12px;
      line-height: 24px;
    }

    // .form-group {
    //   margin-bottom: 25px;
    // }

    .textbox-form-container {
      border: none;
      border-bottom: 1px solid rgba(255, 255, 255, 0.25);
      border-radius: 0;
      background-color: transparent;

      input {
        color: #ffffff;
        font-family: 'Messina Sans';
        font-size: 11px;
        line-height: 18px;
        border: none;
        background: transparent;
        border-radius: 0;
        padding: 0 0 8px 0;
      }
    }

    .passsword-form-group {
      position: relative;
    }

    .password-show-hide {
      border: none;
      background-color: transparent;
      border-radius: 0;
      position: static;

      input {
        color: #ffffff;
        font-family: 'Messina Sans';
        font-size: 14px;
        line-height: 24px;
        border: none;
        border-bottom: 1px solid rgba(255, 255, 255, 0.25);
        background: transparent;
        border-radius: 0;
        padding: 0 0 8px 0;
      }

      .input-validation-msg {
        height: 29px;
        bottom: 5px;
        right: 0;
        top: unset;
      }

      .btn-icon {
        padding: 0;
        margin: 0;
        position: absolute;
        top: 0;
        right: 0;
      }
    }

    .btn-login {
      color: #ffffff;
      font-family: 'Messina Sans';
      font-size: 12px;
      line-height: 19px;
      text-align: center;
      font-weight: normal;
      height: 45px;
      width: 163px;
      margin-top: 40px;
    }
  }
  #wrapper {
    display: flex;
    justify-content: space-between;
    width: 800px;
    max-width: 800px;
  }
  #wrapper > div {
    flex-grow: 1;
  }
  .wrapper-div + .wrapper-div {
    border-left: solid 1px white;
  }

  .form-register {
    padding: 30px 15px;
    width: 380px;
    max-width: 380px;
    height: 380px;
    // background-color   : #fff;
    // border-radius      : 10px;
    position: absolute;
    left: 50%;
    top: 150px;
    transform: translate(-50%, 0);
  }
}
